export default [
  {
    header: 'DCKK RATE',
  },
  {
    title: 'รถ',
    route: 'rate-dckk-motorbike-management',
    icon: '',
  },
  {
    title: 'ไฟแนนซ์',
    route: 'rate-dckk-finance-management',
    icon: '',
  },
  {
    title: 'เรท',
    route: 'rate-dckk-rate-management',
    icon: '',
  },
  {
    title: 'ไปที่หน้าคำนวณเรท',
    route: 'rate-dckk-sale-view',
    icon: '',
  },
  {
    title: 'Excel',
    route: 'rate-dckk-excel',
    icon: '',
  },
]
